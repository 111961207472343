import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get(path: string, params?: HttpParams): Observable<any> {
    return this.http.get(`${path}`, { params: params }).pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${path}`, body).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${path}`, body).pipe(catchError(this.formatErrors));
  }

  delete(path: string, params?: URLSearchParams | HttpParams): Observable<any> {
    return params
      ? this.http.delete(`${path}?${params.toString()}`).pipe(catchError(this.formatErrors))
      : this.http.delete(path).pipe(catchError(this.formatErrors));
  }

  uploadFile(path: string): Observable<any> {
    return this.http.get(`${path}`, { responseType: 'blob' }).pipe(catchError(this.formatErrors));
  }

  // Need this for upload progress bar functioanlity to access HttpEvent on response.
  uploadVideo(path: string, body: Object = {}): Observable<HttpEvent<any>> {
    return this.http.request(
      new HttpRequest('POST', path, body, {
        reportProgress: true,
      })
    );
  }

  private formatErrors(error: any): Observable<never> {
    return throwError(() => error);
  }
}
