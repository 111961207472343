import { ElementRef, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

interface LoaderConfig {
  component?: ElementRef;
  height?: number;
  opacity?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private templateRef!: ElementRef;
  private spinnerRef!: MatProgressSpinner;
  private renderer: Renderer2;
  counter: number = 0

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  setLocalLoaderTemplate(template: ElementRef, spinner: MatProgressSpinner): void {
    this.spinnerRef = spinner;
    this.templateRef = template;
  }

  startLoadingComponent({ component, height = 70, opacity = true }: LoaderConfig) {
    if (component === undefined) {
      return;
    }

    this.spinnerRef.diameter = height;

    const componentElement: HTMLElement = component.nativeElement;
    componentElement.setAttribute('style', 'position: relative');

    if (!opacity) {
      this.templateRef.nativeElement.children[0].querySelector('.overlay').classList.add('no-opacity');
    }
    componentElement.insertAdjacentHTML('beforeend', this.templateRef.nativeElement.innerHTML);
  }

  finishLoadingComponent(component?: ElementRef) {
    if (component === undefined) {
      return;
    }

    this.templateRef.nativeElement.children[0].querySelector('.overlay').classList.remove('no-opacity');
    const element: HTMLElement = component.nativeElement;
    const loadingCompTemplate = element.querySelector(':scope > .component-loader');
    this.renderer.removeChild(element, loadingCompTemplate);
  }
}
