import { environment as env } from '../../../src/environments/environment';

const baseApiUrl = `${env.baseUrl}/api`;
const baseGetAll = 'GetAll';

const USER_CONTROLLER_ROUTE = baseApiUrl + '/Users/';
const TEAM_CONTROLLER_ROUTE = baseApiUrl + '/Teams/';
const AUTH_CONTROLLER_ROUTE = baseApiUrl + '/Auth/';
const EMAIL_CAMPAIGN_ROUTE = baseApiUrl + '/EmailCampaigns/';
const COUNTRIES_ROUTE = baseApiUrl + '/Countries/';
const REGIONS_ROUTE = baseApiUrl + '/Regions/';
const MEDIA_FILES_ROUTE = baseApiUrl + '/MediaFiles/';
const SECTIONS_ROUTE = baseApiUrl + '/Sections/';
const VIDEOS_ROUTE = baseApiUrl + '/Videos/';
const KNOWLEDGE_TESTS_ROUTE = baseApiUrl + '/KnowledgeTests/';
const ADAPTIVE_QUIZZES_ROUTE = baseApiUrl + '/AdaptiveQuizzes/';
const QUESTIONS_ROUTE = baseApiUrl + '/Questions/';
const ANSWERS_ROUTE = baseApiUrl + '/Answers/';
const COURSES_ROUTE = baseApiUrl + '/Courses/';
const MODULES_ROUTE = baseApiUrl + '/Modules/';
const RESPONDED_ROUTE = baseApiUrl + '/Responded/';
const BOOKMARK_NOTES_ROUTE = baseApiUrl + '/BookmarkNotes/';
const BRANDING_ROUTE = baseApiUrl + '/Branding/';

export const USERS_URLS = {
  GET_ALL_CREATED_BY_CURRENT_TEAM_LEAD_BY_PAGE_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'GetAllCreatedByCurrentTeamLeadByPage',
  GET_ALL_CREATED_BY_CURRENT_TEAM_LEAD_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'GetAllCreatedByCurrentTeamLead',
  GET_MY_PROFILE_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'GetMyProfile',
  CREATE_TEAM_MEMBER_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'CreateTeamMember',
  EDIT_TEAM_MEMBER_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'EditTeamMember',
  EDIT_GLOBAL_ROLE_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'EditGlobalRole',
  EDIT_USER_RIGHT_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'EditUserRight',
  EDIT_STATUS_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'EditStatus',
  UPDATE_MY_WELCOME_MESSAGE_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'UpdateMyWelcomeMessage',
  ADD_TEAM_MEMBER_TO_TEAM_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'AddTeamMemberToTeam',
  GET_ALL_USERS_BY_PAGE_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'GetAllByPage',
  GET_ALL_USERS_ROUTE: `${USER_CONTROLLER_ROUTE}` + baseGetAll,
  GET_TEAM_LEAD_ID: `${USER_CONTROLLER_ROUTE}` + 'GetTeamLeadId',
  LOGIN_ROUTE: `${AUTH_CONTROLLER_ROUTE}` + 'Login',
  AUTHORIZE_ROUTE: `${AUTH_CONTROLLER_ROUTE}` + 'Authorize',
  FORGOT_PASSWORD: `${AUTH_CONTROLLER_ROUTE}` + 'ForgotPassword',
  RESET_PASSWORD: `${AUTH_CONTROLLER_ROUTE}` + 'ResetPassword',
  SEND_WELCOME_EMAIL: `${USER_CONTROLLER_ROUTE}` + 'SendWelcomeEmail',
  GET_ALL_ADMINS_BY_PAGE_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'GetAllAdminsByPage',
  CREATE_ADMIN_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'CreateAdmin',
  EDIT_ADMIN_ROUTE: `${USER_CONTROLLER_ROUTE}` + 'EditAdmin',
  GET_ALL_USERS_BY_TEAM: `${USER_CONTROLLER_ROUTE}` + 'GetAllUsersByTeam',
};

export const TEAMS_URLS = {
  ALL_TEAMS: `${TEAM_CONTROLLER_ROUTE}` + baseGetAll,
  CREATE_TEAM: `${TEAM_CONTROLLER_ROUTE}` + 'CreateTeam',
  EDIT_TEAM: `${TEAM_CONTROLLER_ROUTE}` + 'EditTeam',
  DELETE_TEAM: `${TEAM_CONTROLLER_ROUTE}` + 'DeleteTeam',
  CURRENT_TEAM: `${TEAM_CONTROLLER_ROUTE}` + 'GetById',
};

export const EMAIL_CAMPAIGNS_URLS = {
  UPDATE_EMAIL_CAMPAIGN: `${EMAIL_CAMPAIGN_ROUTE}` + 'UpdateEmailCampaign',
  GET_CURRENT_EMAIL_CAMPAIGN: `${EMAIL_CAMPAIGN_ROUTE}` + 'GetCurrentEmailCampaign',
  GET_EMAIL_CAMPAIGN_IMAGE: `${EMAIL_CAMPAIGN_ROUTE}` + 'GetEmailCampaignImage',
  GET_EMAIL_IMAGE_DATA: `${EMAIL_CAMPAIGN_ROUTE}` + 'DownloadEmailImageData',
  TEST_EMAIL: `${EMAIL_CAMPAIGN_ROUTE}` + 'SendTestEmail',
  SEND_PARTNER_EMAIL: `${EMAIL_CAMPAIGN_ROUTE}` + 'SendPartnerEmail',
};

export const COUNTRIES_URLS = {
  GET_ALL: `${COUNTRIES_ROUTE}` + 'GetAll',
  GET_ALL_BY_REGION_ID: `${COUNTRIES_ROUTE}` + 'GetAllByRegionId',
  GET_ALL_BY_REGION_NAME: `${COUNTRIES_ROUTE}` + 'GetAllByRegionName',
  GET_BY_ID: `${COUNTRIES_ROUTE}` + 'GetById',
};

export const REGIONS_URLS = {
  GET_ALL: `${REGIONS_ROUTE}` + 'GetAll',
  GET_BY_ID: `${REGIONS_ROUTE}` + 'GetById',
};

export const MEDIA_FILES_URLS = {
  GET_BY_ID_AND_UPDATE_URL: `${MEDIA_FILES_ROUTE}` + 'GetByIdAndUpdateUrl',
  GET_GET_ACCESS_VIDEO_URL: `${MEDIA_FILES_ROUTE}` + 'GetGetAccessVideo',
};

export const SECTIONS_URLS = {
  GET_ALL_SECTIONS: `${SECTIONS_ROUTE}` + 'GetAll',
  GET_SECTION_OVERVIEW: `${SECTIONS_ROUTE}` + 'GetSectionOverview',
  GET_SECTION_BY_ID: `${SECTIONS_ROUTE}` + 'GetById',
  CREATE_SECTION: `${SECTIONS_ROUTE}` + 'CreateSection',
  UPDATE_SECTION: `${SECTIONS_ROUTE}` + 'UpdateSection',
  DELETE_SECTION: `${SECTIONS_ROUTE}` + 'DeleteSection',
};

export const VIDEOS_URLS = {
  GET_ALL_VIDEOS: `${VIDEOS_ROUTE}` + 'GetAll',
  GET_VIDEO_BY_ID: `${VIDEOS_ROUTE}` + 'GetById',
  CREATE_VIDEO_SECTION: `${VIDEOS_ROUTE}` + 'CreateVideoSection',
  UPLOAD_VIDEO: `${VIDEOS_ROUTE}` + 'UploadVideo',
  REMOVE_VIDEO_DATA: `${VIDEOS_ROUTE}` + 'DeleteVideo',
  UPDATE_VIDEO_TITLE: `${VIDEOS_ROUTE}` + 'UpdateVideoTitle',
  UPDATE_VIDEO_DESCRIPTION: `${VIDEOS_ROUTE}` + 'UpdateVideoDescription',
  REMOVE_VIDEO_SECTION: `${VIDEOS_ROUTE}` + 'RemoveVideoSection',
};

export const KNOWLEDGE_TEST_URLS = {
  GET_ALL_KNOWLEDGE_TESTS: `${KNOWLEDGE_TESTS_ROUTE}` + 'GetAll',
  GET_ALL_NOT_ADDED_KNOWLEDGE_TESTS: `${KNOWLEDGE_TESTS_ROUTE}` + 'GetAllNotAddedKnowledgeTestsToAdaptiveQuiz',
  GET_KNOWLEDGE_TEST_BY_ID: `${KNOWLEDGE_TESTS_ROUTE}` + 'GetById',
  CREATE_KNOWLEDGE_TEST_SECTION: `${KNOWLEDGE_TESTS_ROUTE}` + 'CreateKnowledgeTestSection',
  UPDATE_KNOWLEDGE_TEST_TITLE: `${KNOWLEDGE_TESTS_ROUTE}` + 'UpdateKnowledgeTestTitle',
  UPDATE_KNOWLEDGE_TEST_DESCRIPTION: `${KNOWLEDGE_TESTS_ROUTE}` + 'UpdateKnowledgeTestDescription',
  REMOVE_KNOWLEDGE_TEST_SECTION: `${KNOWLEDGE_TESTS_ROUTE}` + 'RemoveKnowledgeTestSection',
};

export const ADDAPTIVE_QUIZ_URLS = {
  GET_ALL_ADAPTIVE_QUIZZES: `${ADAPTIVE_QUIZZES_ROUTE}` + 'GetAll',
  GET_ADAPTIVE_QUIZ_BY_ID: `${ADAPTIVE_QUIZZES_ROUTE}` + 'GetById',
  CREATE_ADAPTIVE_QUIZ_SECTION: `${ADAPTIVE_QUIZZES_ROUTE}` + 'CreateAdaptiveQuizSection',
  UPDATE_ADAPTIVE_QUIZ_TITLE: `${ADAPTIVE_QUIZZES_ROUTE}` + 'UpdateAdaptiveQuizTitle',
  ADD_KNOWLEDGE_TEST_TO_ADAPTIVE_QUIZ_: `${ADAPTIVE_QUIZZES_ROUTE}` + 'AddKnowledgeTestToTheAddaptiveQuiz',
  REMOVE_KNOWLEDGE_TEST_FROM_ADAPTIVE_QUIZ_: `${ADAPTIVE_QUIZZES_ROUTE}` + 'RemoveKnowledgeTestFromTheAddaptiveQuiz',
  DELETE_ADAPTIVE_QUIZ_SECTION: `${ADAPTIVE_QUIZZES_ROUTE}` + 'DeleteAdaptiveQuizSection',
};

export const QUESTIONS_URLS = {
  CREATE_VIDEO_QUESTION: `${QUESTIONS_ROUTE}` + 'CreateVideoQuestion',
  CREATE_KNOWLEDGE_TEST_QUESTION: `${QUESTIONS_ROUTE}` + 'CreateKnowledgeTestQuestion',
  CREATE_VIDEO_NESTED_QUESTION: `${QUESTIONS_ROUTE}` + 'CreateNestedVideoQuestion',
  CREATE_KNOWLEDGE_TEST_NESTED_QUESTION: `${QUESTIONS_ROUTE}` + 'CreateNestedKnowledgeTestQuestion',
  UPDATE_STATEMENT: `${QUESTIONS_ROUTE}` + 'UpdateStatement',
  UPDATE_FILL_IN_THE_BLANKS_STATEMENT: `${QUESTIONS_ROUTE}` + 'UpdateFillInTheBlanksStatement',
  UPDATE_PRECHECK_VIDEO_ORDER: `${QUESTIONS_ROUTE}` + 'UpdatePrecheckQuestionsOrder',
  UPDATE_STANDART_VIDEO_ORDER: `${QUESTIONS_ROUTE}` + 'UpdateStandartQuestionsOrder',
  UPDATE_KNOWLEDGE_TEST_ORDER: `${QUESTIONS_ROUTE}` + 'UpdateKnowledgeTestQuestionsOrder',
  UPDATE_NESTED_ORDER: `${QUESTIONS_ROUTE}` + 'UpdateNestedOrder',
  UPDATE_TIMESTAMP: `${QUESTIONS_ROUTE}` + 'UpdateTimestamp',
  UPDATE_WRONG_ANSWER_EXPLANATION: `${QUESTIONS_ROUTE}` + 'UpdateWrongAnswerExplanation',
  UPDATE_CORRECT_ANSWER_EXPLANATION: `${QUESTIONS_ROUTE}` + 'UpdateCorrectAnswerExplanation',
  UPLOAD_IMAGE: `${QUESTIONS_ROUTE}` + 'UploadImage',
  REMOVE_IMAGE: `${QUESTIONS_ROUTE}` + 'RemoveImage',
  REMOVE_NESTED_QUESTION: `${QUESTIONS_ROUTE}` + 'RemoveNestedQuestion',
  REMOVE_VIDEO_QUESTION: `${QUESTIONS_ROUTE}` + 'RemoveVideoQuestion',
  REMOVE_KNOWLEDGE_TEST_QUESTION: `${QUESTIONS_ROUTE}` + 'RemoveKnowledgeTestQuestion',
  GET_ALL_VIDEO_QUESTION: `${QUESTIONS_ROUTE}` + 'GetAllVideoQuestions',
  GET_ALL_DUPLICATE_TIMESTAMP_QUESTION: `${QUESTIONS_ROUTE}` + 'GetDuplicateTimestampQuestions',
  GET_ALL_KNOWLEDGE_TEST_QUESTION: `${QUESTIONS_ROUTE}` + 'GetAllKnowledgeTestQuestions',
  GET_ALL_NESTED_QUESTION: `${QUESTIONS_ROUTE}` + 'GetAllNested',
  GET_BY_ID_QUESTION: `${QUESTIONS_ROUTE}` + 'GetById',
};

export const ANSWERS_URLS = {
  GET_ALL_ANSWERS: `${ANSWERS_ROUTE}` + 'GetAll',
  GET_ANSWER_BY_ID: `${ANSWERS_ROUTE}` + 'GetById',
  UPDATE_ANSWER: `${ANSWERS_ROUTE}` + 'UpdateAnswer',
  UPDATE_EXPLANATION: `${ANSWERS_ROUTE}` + 'UpdateExplanation',
  UPDATE_SINGLE_ANSWER_CORRECTNESS: `${ANSWERS_ROUTE}` + 'UpdateSingleAnswerCorrectness',
  UPDATE_MULTIPLE_ANSWER_CORRECTNESS: `${ANSWERS_ROUTE}` + 'UpdateMultipleAnswerCorrectness',
  UPDATE_ORDER: `${ANSWERS_ROUTE}` + 'UpdateOrder',
  REMOVE_AND_REORDER_ANSWERS: `${ANSWERS_ROUTE}` + 'RemoveOrderedAnswer',
  REMOVE_ANSWER: `${ANSWERS_ROUTE}` + 'RemoveAnswer',
  CREATE_INITIAL_ANSWERS: `${ANSWERS_ROUTE}` + 'CreateInitialAnswers',
  CREATE_ANSWER: `${ANSWERS_ROUTE}` + 'CreateAnswer',
  UPLOAD_ANSWER_IMAGE: `${ANSWERS_ROUTE}` + 'UploadAnswerImage',
  REMOVE_ANSWER_IMAGE: `${ANSWERS_ROUTE}` + 'RemoveAnswerImage',
};

export const BOOKMARK_NOTES_URLS = {
  GET_ALL_VIDEO_BOOKMARKS: `${BOOKMARK_NOTES_ROUTE}` + 'GetAllVideoBookmarks',
  GET_ALL_KNOWLEDGE_TESTS_BOOKMARKS: `${BOOKMARK_NOTES_ROUTE}` + 'GetAllKnowledgeTestBookmarks',
  CREATE_BOOKMARK: `${BOOKMARK_NOTES_ROUTE}` + 'CreateBookmark',
  UPDATE_BOOKMARK: `${BOOKMARK_NOTES_ROUTE}` + 'UpdateBookmark',
  REMOVE_BOOKMARK: `${BOOKMARK_NOTES_ROUTE}` + 'RemoveBookmark',
};

export const BRANDING_URLS = {
  GET_BRANDING: `${BRANDING_ROUTE}` + 'GetBranding',
  UPDATE_BRANDING_LOGO: `${BRANDING_ROUTE}` + 'UpdateBrandingLogo',
  UPDATE_FOOTER_FREE_TEXT: `${BRANDING_ROUTE}` + 'UpdateFooterFreeText',
  UPDATE_OTHER_TYPES: `${BRANDING_ROUTE}` + 'UpdateOtherTypes',
};

export const COURSES_URLS = {
  CREATE_COURSE: `${COURSES_ROUTE}` + 'CreateCourse',
  DUPLICATE_COURSE: `${COURSES_ROUTE}` + 'DuplicateCourse',
  DELETE_DRAFTED_COURSE: `${COURSES_ROUTE}` + 'DeleteDraftedCourse',
  EDIT_COURSE: `${COURSES_ROUTE}` + 'EditCourse',
  UPDATE_COURSE_STATUS: `${COURSES_ROUTE}` + 'UpdateCourseStatus',
  UPDATE_COURSE_PUBLISH_PERMISSION: `${COURSES_ROUTE}` + 'UpdateCoursePublishPermission',
  GET_ALL_COURSES: `${COURSES_ROUTE}` + 'GetAll',
  GET_USER_COURSE_WITH_NESTED: `${COURSES_ROUTE}` + 'GetCurrentUserCourseWithNestedData',
  GET_TEAM_MEMBER_USER_COURSE: `${COURSES_ROUTE}` + 'GetTeamMemberUserCourse',
  GET_TEAM_MEMBER_USER_COURSE_REPORT: `${COURSES_ROUTE}` + 'GetUserCourseReport',
  GET_TEAM_MEMBER_USER_COURSE_REPORT_MASTERED_QUESTIONS: `${COURSES_ROUTE}` + 'GetCourseReportMasteredQuestions',
  GET_TEAM_MEMBER_USER_COURSE_REPORT_IMRPOVE_QUESTIONS: `${COURSES_ROUTE}` + 'GetCourseReportImrpoveQuestions',
  GET_TEAM_MEMBER_USER_COURSE_REPORT_REVIEW_QUESTIONS: `${COURSES_ROUTE}` + 'GetCourseReportReviewQuestions',
  GET_TEAM_MEMBER_USER_COURSE_REPORT_ALL_QUESTIONS: `${COURSES_ROUTE}` + 'GetCourseReportAllQuestions',
  GET_COURSE_PROGRESS: `${COURSES_ROUTE}` + 'GetCourseProgress',
  GET_COURSE_BY_ID: `${COURSES_ROUTE}` + 'GetById',
  PUBLISH_COURSE: `${COURSES_ROUTE}` + 'PublishCourse',
  ADD_UPDATE_COURSE_COUNTRIES: `${COURSES_ROUTE}` + 'AddUpdateCourseCountries',
  REMOVE_COURSE_COUNTRY: `${COURSES_ROUTE}` + 'RemoveCourseCountry',
  GET_ALL_PUBLISHED_ACTIVE_COURSES: `${COURSES_ROUTE}` + 'GetAllPublishedActiveCourses',
  GET_ALL_ASSIGNED_ACTIVE_COURSES: `${COURSES_ROUTE}` + 'GetAllAsignedCourses',
  GET_ALL_COURSES_WITH_PROGRESS: `${COURSES_ROUTE}` + 'GetAllCoursesWithProgress',
  GET_ALL_COUNTRIES_IN_COURSE: `${COURSES_ROUTE}` + 'GetAllCountriesInCourrentCourse',
  GET_COURSE_CONTENT_WITH_PROGRESS: `${COURSES_ROUTE}` + 'GetCourseContentWithProgress',
  GET_VIDEO_WITH_WRONG_ANSWERS_ID: `${COURSES_ROUTE}` + 'GetVideoWithWrongAnswersId',
  ASSIGN_USER_TO_COURSE: `${COURSES_ROUTE}` + 'AssignUserToCourse',
  UNASSIGN_USER_FROM_COURSE: `${COURSES_ROUTE}` + 'UnassignUserFromCourse',
  GET_CURRENT_USER_COURSE: `${COURSES_ROUTE}` + 'GetCurrentUserCourse',
  GET_ALL_USER_COURSES_BY_PAGE: `${COURSES_ROUTE}` + 'GetAllUserCoursesByPage',
  GET_ALL_USER_COURSES: `${COURSES_ROUTE}` + 'GetAllUserCourses',
  UPDATE_USER_COURSE_DATE: `${COURSES_ROUTE}` + 'UpdateUserCourseDate',
  GET_FILTRED_DATA: `${COURSES_ROUTE}` + 'GetFiltredData',
  IS_COURSE_COMPLETE_CONTENT: `${RESPONDED_ROUTE}` + 'IsCourseCompletedCalculation',
};

export const MODULES_URLS = {
  CREATE_MODULE: `${MODULES_ROUTE}` + 'CreateModule',
  DELETE_MODULE: `${MODULES_ROUTE}` + 'DeleteModule',
  EDIT_MODULE_TITLE: `${MODULES_ROUTE}` + 'EditModuleTitle',
  EDIT_MODULE_SUBTITLE: `${MODULES_ROUTE}` + 'EditModuleSubtitle',
  GET_ALL_MODULES: `${MODULES_ROUTE}` + 'GetAll',
  GET_ALL_MODULE_IDS: `${MODULES_ROUTE}` + 'GetAllModuleIds',
  GET_CURRENT_MODULE: `${MODULES_ROUTE}` + 'GetCurrent',
};

export const RESPONDED_URLS = {
  GET_LAST_CONTENT: `${RESPONDED_ROUTE}` + 'GetLastContent',
  GET_COURSE_QUESTIONS_WITH_WRONG_ANSWERS: `${RESPONDED_ROUTE}` + 'GetCourseQuestionsWithWrongAnswers',
  GET_SELECTED_CONTENT: `${RESPONDED_ROUTE}` + 'GetSelectedContent',
  GET_NEXT_KNOWLEDGE_TEST_QUESTION: `${RESPONDED_ROUTE}` + 'GetNextKnowledgeTestQuestion',
  GET_NEXT_VIDEO_QUESTION_OR_VIDEO_CONTINUE: `${RESPONDED_ROUTE}` + 'GetNextVideoQuestionOrVideoContinue',
  CREATE_RESPONDED_KNOWLEDGE_TEST_QUESTION_WITH_ANSWERS: `${RESPONDED_ROUTE}` + 'CreateRespondedKnowledgeTestQuestionWithAnswers',
  CREATE_RESPONDED_VIDEO_QUESTION_WITH_ANSWERS: `${RESPONDED_ROUTE}` + 'CreateRespondedVideoQuestionWithAnswers',
};
