import { EventEmitter, Injectable } from '@angular/core';
import * as RouteConstants from '../constants/route.constants';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { LoginRequest } from '../submodules/admin/models/login-request.model';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpParams } from '@angular/common/http';
import { AUTH_PREFIX } from '../constants/text.constants';
import { ResetPasswordRequest } from '../submodules/admin/models/reset-password-request.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userName = '';
  userEmail = '';
  userId = '';
  userFirstName = '';
  userLastName = '';
  userRole = '';
  userRegion = '';
  userCountry = '';
  userIsActive: boolean = false;
  userIsGlobal: boolean = false;
  userDataChanged: EventEmitter<void> = new EventEmitter<void>();
  private routeConstants = RouteConstants.USERS_URLS;
  private handlingChange = false;
  private jwtHelperService = new JwtHelperService();
  tokenExpiration: any;

  constructor(private api: ApiService, private cookieService: CookieService, private router: Router) {}

  get isLoggedIn(): boolean {
    return this.cookieService.check('_elpat');
  }

  signup(request: LoginRequest): Observable<boolean> {
    return this.api.post(this.routeConstants.LOGIN_ROUTE, request).pipe(
      tap((resData: any) => {
        const token = resData.jwtToken;
        this.cookieService.set('_elpat', token, { path: '/' });
      }),
      catchError((errorRes) => {
        return throwError(errorRes);
      })
    );
  }

  logout() {
    this.cookieService.delete('_elpat', '/');

    this.userName = '';
    this.userEmail = '';
    this.userId = '';
    this.userFirstName = '';
    this.userLastName = '';
    this.userRole = '';
    this.userRegion = '';
    this.userCountry = '';
    this.userIsActive = false;
    this.userIsGlobal = false;
  }

  forgotPassword(email: string) {
    const params = new HttpParams().set('email', email);

    return this.api.get(this.routeConstants.FORGOT_PASSWORD, params);
  }

  resetPassword(request: ResetPasswordRequest) {
    return this.api.put(this.routeConstants.RESET_PASSWORD, request);
  }

  getToken(): string {
    return this.cookieService.get('_elpat');
  }

  authorize(): Observable<any> {
    var queryParams = new HttpParams();
    queryParams = queryParams.append('returnUrl', this.router.url);

    return this.api.post(this.routeConstants.AUTHORIZE_ROUTE, { params: queryParams });
  }

  isInRole(roles: string[]): boolean {
    roles = roles.map((x) => x.toLowerCase());
    return roles.includes(this.userRole?.toLowerCase()) ?? false;
  }

  setUserData() {
    const token = this.cookieService.get('_elpat');
    if (token !== undefined && token !== '') {
      const decodedToken = this.jwtHelperService.decodeToken(token);
      this.tokenExpiration = new Date(decodedToken.exp * 1000);
      this.userEmail = decodedToken.email;
      this.userName = decodedToken.unique_name;
      this.userFirstName = decodedToken.given_name;
      this.userLastName = decodedToken.family_name;
      this.userId = decodedToken.nameid;
      this.userRole = decodedToken.role;
      this.userIsActive = decodedToken.IsActive === 'True';
      const countryClaimKey = AUTH_PREFIX;
      const regionClaimKey = 'Region';
      this.userCountry = decodedToken[countryClaimKey];
      this.userRegion = decodedToken[regionClaimKey];
      this.userIsGlobal = decodedToken.IsGlobal === 'True';
      if (!this.handlingChange) {
        this.handlingChange = true;
        this.userDataChanged.emit();
        this.handlingChange = false;
      }
    }
  }
}
