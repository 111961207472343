import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-e-icon-button',
  templateUrl: './e-icon-button.component.html',
  styleUrl: './e-icon-button.component.scss',
})
export class EIconButtonComponent {
  @Input() title: string = '';
  @Input() content: string = '';
  @Input() iconSrc: string = '';
  @Input() iconSrcHover: string = '';
  @Input() disableHover: boolean = false;
  @Output() iconClick = new EventEmitter<void>();

  isHovered: boolean = false;

  onIconClick() {
    this.iconClick.emit();
  }

  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }
}
