import { HttpParams } from '@angular/common/http';
import * as RouteConstants from '../constants/route.constants';
import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, tap } from 'rxjs';
import { CourseListingModel } from '../submodules/admin/models/content-manager/courses/CourseListingModel';
import { CoursePublishModel } from '../submodules/admin/models/content-manager/courses/CoursePublishModel';
import { UserCourseListingModel } from '../submodules/admin/models/content-manager/courses/UserCourseListingModel';
import { UserCoursesResponseModel } from '../submodules/admin/models/content-manager/courses/UserCoursesResponseModel';
import { AssignUserToCourseRequestModel } from '../submodules/admin/models/content-manager/courses/AssignUserToCourseRequestModel';
import { UserCourseEditDatesRequestModel } from '../submodules/admin/models/content-manager/courses/UserCourseEditDatesRequestModel';
import { UserCourseNestedListingModel } from '../submodules/team-member/models/UserCourseNestedListingModel';
import { UserCourseProgressListingModel } from '../submodules/team-member/models/UserCourseProgressListingModel';
import { TeamMemberUserCourseListingModel } from '../submodules/team-member/models/TeamMemberUserCourseListingModel';
import { FiltredDataRequestModel } from '../submodules/admin/models/admin-super-admin/filtred-data-request.model';
import { FiltredDataResponseModel } from '../submodules/admin/models/admin-super-admin/filtred-data-response.model';
import { AddUpdateCourseCountriesRequestModel } from '../submodules/admin/models/content-manager/courses/AddUpdateCourseCountriesRequestModel';
import { TeamMemberUserCourseReportModel } from '../submodules/team-member/models/TeamMemberUserCourseReportModel';
import { TeamMemberReportQuestionsListingModel } from '../submodules/team-member/models/TeamMemberReportQuestionsListingModel';
import { TeamMemberReportQuestionTablesModel } from '../submodules/team-member/models/TeamMemberReportQuestionTablesModel';

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  userCourseAdded = new EventEmitter<void>();
  private routeConstants = RouteConstants.COURSES_URLS;

  constructor(private api: ApiService) {}

  getAll(isPublished: boolean): Observable<CourseListingModel[]> {
    let params = new HttpParams();
    params = params.set('isPublished', isPublished);

    return this.api.get(this.routeConstants.GET_ALL_COURSES, params);
  }

  getTeamMemberUserCourse(courseId: string): Observable<TeamMemberUserCourseListingModel> {
    let params = new HttpParams();
    params = params.set('courseId', courseId);

    return this.api.get(this.routeConstants.GET_TEAM_MEMBER_USER_COURSE, params);
  }

  getTeamMemberUserCourseReport(courseId: string): Observable<TeamMemberUserCourseReportModel> {
    let params = new HttpParams();
    params = params.set('courseId', courseId);

    return this.api.get(this.routeConstants.GET_TEAM_MEMBER_USER_COURSE_REPORT, params);
  }

  getTeamMemberReportQuestionsMastered(courseId: string, page: number): Observable<TeamMemberReportQuestionsListingModel> {
    let params = new HttpParams();
    params = params.set('courseId', courseId);
    params = params.set('page', page);

    return this.api.get(this.routeConstants.GET_TEAM_MEMBER_USER_COURSE_REPORT_MASTERED_QUESTIONS, params);
  }

  getTeamMemberReportQuestionsImprove(courseId: string, page: number): Observable<TeamMemberReportQuestionsListingModel> {
    let params = new HttpParams();
    params = params.set('courseId', courseId);
    params = params.set('page', page);

    return this.api.get(this.routeConstants.GET_TEAM_MEMBER_USER_COURSE_REPORT_IMRPOVE_QUESTIONS, params);
  }

  getTeamMemberReportQuestionsReview(courseId: string, page: number): Observable<TeamMemberReportQuestionsListingModel> {
    let params = new HttpParams();
    params = params.set('courseId', courseId);
    params = params.set('page', page);

    return this.api.get(this.routeConstants.GET_TEAM_MEMBER_USER_COURSE_REPORT_REVIEW_QUESTIONS, params);
  }

  getTeamMemberReportAllQuestions(courseId: string): Observable<TeamMemberReportQuestionTablesModel> {
    let params = new HttpParams();
    params = params.set('courseId', courseId);

    return this.api.get(this.routeConstants.GET_TEAM_MEMBER_USER_COURSE_REPORT_ALL_QUESTIONS, params);
  }

  getUserCourseWithNestedData(courseId: string): Observable<UserCourseNestedListingModel> {
    let params = new HttpParams();
    params = params.set('id', courseId);

    return this.api.get(this.routeConstants.GET_USER_COURSE_WITH_NESTED, params);
  }

  getCourseProgress(courseId: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('id', courseId);

    return this.api.get(this.routeConstants.GET_COURSE_PROGRESS, params);
  }

  getById(id: string): Observable<CourseListingModel> {
    let params = new HttpParams();
    params = params.set('id', id);

    return this.api.get(this.routeConstants.GET_COURSE_BY_ID, params);
  }

  createCourse(request: FormData): Observable<boolean> {
    return this.api.post(this.routeConstants.CREATE_COURSE, request);
  }

  duplicateCourse(model: CoursePublishModel): Observable<string> {
    return this.api.post(this.routeConstants.DUPLICATE_COURSE, model);
  }

  editCourse(request: FormData): Observable<boolean> {
    return this.api.put(this.routeConstants.EDIT_COURSE, request);
  }

  updateCourseStatus(model: CoursePublishModel) {
    return this.api.put(this.routeConstants.UPDATE_COURSE_STATUS, model);
  }

  updateCoursePublishPermission(model: CoursePublishModel) {
    return this.api.put(this.routeConstants.UPDATE_COURSE_PUBLISH_PERMISSION, model);
  }

  publishCourse(model: CoursePublishModel): Observable<any> {
    return this.api.put(this.routeConstants.PUBLISH_COURSE, model);
  }

  getAllPublishedActiveCourses(): Observable<CourseListingModel[]> {
    return this.api.get(this.routeConstants.GET_ALL_PUBLISHED_ACTIVE_COURSES);
  }

  getAllAssignedCourses(): Observable<CourseListingModel[]> {
    return this.api.get(this.routeConstants.GET_ALL_ASSIGNED_ACTIVE_COURSES);
  }

  assignUserToCourse(request: AssignUserToCourseRequestModel) {
    return this.api.post(this.routeConstants.ASSIGN_USER_TO_COURSE, request).pipe(
      tap(() => {
        this.userCourseAdded.emit();
      })
    );
  }

  unassignUserFromCourse(id: string) {
    const params = new HttpParams().set('id', id);
    return this.api.delete(this.routeConstants.UNASSIGN_USER_FROM_COURSE, params);
  }

  getCurrentUserCourse(id: string): Observable<UserCourseListingModel> {
    let params = new HttpParams();
    params = params.set('id', id);

    return this.api.get(this.routeConstants.GET_CURRENT_USER_COURSE, params);
  }

  getAllUserCoursesByPage(page: number = 1, searchTerm?: string): Observable<UserCoursesResponseModel> {
    let params = new HttpParams().set('page', page.toString());

    if (searchTerm) {
      params = params.set('startsWith', searchTerm);
    }

    return this.api.get(this.routeConstants.GET_ALL_USER_COURSES_BY_PAGE, params);
  }

  getAllUserCourses(searchTerm?: string): Observable<UserCourseListingModel[]> {
    let params = new HttpParams();
    if (searchTerm) {
      params = params.set('startsWith', searchTerm);
    }

    return this.api.get(this.routeConstants.GET_ALL_USER_COURSES, params);
  }

  updateUserCourseDate(request: UserCourseEditDatesRequestModel): Observable<boolean> {
    return this.api.put(this.routeConstants.UPDATE_USER_COURSE_DATE, request);
  }

  getAllCoursesWithProgress(): Observable<UserCourseProgressListingModel[]> {
    return this.api.get(this.routeConstants.GET_ALL_COURSES_WITH_PROGRESS);
  }

  getCourseContentWithProgress(courseId: string): Observable<UserCourseProgressListingModel[]> {
    let params = new HttpParams();
    params = params.set('id', courseId);

    return this.api.get(this.routeConstants.GET_COURSE_CONTENT_WITH_PROGRESS, params);
  }

  getVideoWithWrongAnswersId(courseId: string): Observable<string> {
    let params = new HttpParams();
    params = params.set('id', courseId);

    return this.api.get(this.routeConstants.GET_VIDEO_WITH_WRONG_ANSWERS_ID, params);
  }

  getFiltredData(request: FiltredDataRequestModel): Observable<FiltredDataResponseModel> {
    return this.api.post(this.routeConstants.GET_FILTRED_DATA, request);
  }

  addUpdateCourseCountries(model: AddUpdateCourseCountriesRequestModel): Observable<any> {
    return this.api.put(this.routeConstants.ADD_UPDATE_COURSE_COUNTRIES, model);
  }

  removeCourseCountry(model: AddUpdateCourseCountriesRequestModel): Observable<any> {
    return this.api.put(this.routeConstants.REMOVE_COURSE_COUNTRY, model);
  }

  getAllCountriesInCurrentCourse(courseId: string): Observable<string[]> {
    let params = new HttpParams();
    params = params.set('courseId', courseId);

    return this.api.get(this.routeConstants.GET_ALL_COUNTRIES_IN_COURSE, params);
  }

  isCourseCompletedCalculation(courseId: string): Observable<boolean> {
    let params = new HttpParams();

    params = params.set('courseId', courseId);

    return this.api.get(this.routeConstants.IS_COURSE_COMPLETE_CONTENT, params);
  }

  deleteDraftedCourse(courseId: string): Observable<string> {
    let params = new HttpParams();
    params = params.append('courseId', courseId);

    return this.api.delete(this.routeConstants.DELETE_DRAFTED_COURSE, params);
  }
}
