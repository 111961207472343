import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import * as RouteConstants from '../constants/route.constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WelcomeFilesService {
  private routeConstants = RouteConstants.MEDIA_FILES_URLS;

  constructor(private api: ApiService) {}

  getByIdAndUpdateUrl(id: string, containerType: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('id', id);
    params = params.set('containerType', containerType);

    return this.api.get(this.routeConstants.GET_BY_ID_AND_UPDATE_URL, params);
  }

  getGetAccessVideo(id: string, containerType: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('id', id);
    params = params.set('containerType', containerType);

    return this.api.get(this.routeConstants.GET_GET_ACCESS_VIDEO_URL, params);
  }
}
