export const HOME = 'Home';
export const MY_ADMIN_PANEL = 'My admin panel';
export const MY_TEAM = 'My team';
export const CREATE_COURSES = 'Create courses';
export const LEARNING_PLAN = 'Learning plan';
export const YOUR_COURSE_CATALOGUE = 'YOUR COURSE CATALOGUE';
export const CONTINUE_WHERE_YOU_LEFT_OFF = 'CONTINUE WHERE YOU LEFT OFF';
export const LEARNING_PERFORMANCE = 'Learning performance';
export const GO_CREATE_COURSES = 'Go to create courses';
export const GO_MY_COURSES = 'Go to my courses';
export const MY_COURSES = 'My courses';
export const NOTIFICATIONS = 'Notifications';
export const GO_TO_MY_TEAM = 'Go to my team';
export const GO_TO_LEARNING_PLAN = 'Go to learning plan';
export const GO_TO_LEARNING_PERFORMANCE = 'Go to learning performance';
export const GO_TO_WELCOME_MESSAGE = 'Go to welcome message';
export const EDIT_BRANDING = 'Edit branding';
export const GO_TO_BRANDING = 'Go to branding';
export const TEAMS = 'TEAMS';
export const QUICK_START = 'QUICK START';
export const SEARCH = 'Search by name';
export const CREATE_NEW_TEAM = 'Create a new team';
export const FILTER_DATA = 'Filter Data';
export const FILTER = 'Filter';
export const GENERATE = 'Generate';
export const USER_MANAGEMENT = 'USER MANAGEMENT';
export const ADMIN_MANAGEMENT = 'Admin management';
export const GO_TO_ADMIN_MANAGEMENT = 'Go to admin management';
export const ADD_NEW = 'Add new';
export const SET = 'Set';
export const CLICK_TO_SET_TIMESTAMP = 'Click to add and set the current time of the paused video.';
export const CLICK_TO_EDIT_TIMESTAMP = 'Click to add/edit and set the current time of the paused video.';
export const GO_TO_TIMESTAMP = 'GO TO TIMESTAMP';
export const SET_TIMESTAMP = 'SET TIMESTAMP';
export const ADD_VIDEO = 'Add video';
export const ADD_KNOWLEDGE_TEST = 'Add Knowledge Test';
export const CONNECT_TO_KNOWLEDGE_TEST = 'Connect to an existing knowledge test.';
export const ADD_ANSWER = 'Add Answer';
export const ADD_AN_ANSWER = 'Add an Answer';
export const ENTER_A_WORD = 'Enter a word';
export const CREATE_DROPDOWN = 'Create dropdown with possible answers';
export const TYPE_POSSIBLE_WRONG_ANSWER = 'Type a possible answer that the user will see if he or she typed the words wrongly.';
export const TYPE_POSSIBLE_CORRECT_ANSWER = 'Type a possible answer that the user will see if the answer is correct.';
export const EXPLAIN_WHY_BEST_ANSWER = "Explain why this is or isn't the best answer.";
export const ADD_ADAPTIVE_PROGRESS_QUIZ = 'Add Adaptive Progress Quiz';
export const WELCOME_MESSAGE = 'WELCOME MESSAGE';
export const MANAGE_EMAIL_CAMPAIGNE = 'MANAGE EMAIL CAMPAIGN';
export const WELCOME_MEMBER = 'Welcome new team member';
export const NEW_COURSE_ASSIGNED = 'New course assigned';
export const PREVIEW = 'Preview';
export const TEAM = 'Team';
export const MEMBERS = 'Members';
export const REGION = 'Region';
export const COUNTRY = 'Country/region';
export const ACTIONS = 'Actions';
export const NAME = 'Name';
export const LABEL = 'Label';
export const ROLE = 'Role';
export const GLOBAL_ROLE = 'Global role';
export const GLOBAL = 'Global';
export const USER_RIGHTS = 'User rights';
export const EMAIL = 'Email';
export const ASSIGN_COURSE = 'ASSIGN COURSE';
export const GENERATE_REPORT = 'GENERATE REPORT';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const ITEMS_PER_PAGE = 10;
export const SELECT_TYPE_OF_QUESTION = 'Select the type of questions you want to add to this quiz:';
export const SELECT_TYPE_OF_MIXED_QUESTION = 'Select maximum 3 types of questions to add to this quiz:';
export const QUESTION = 'QUESTION';
export const QUIZZES = 'QUIZZES';
export const ANSWER = 'Answer';
export const INDICATE_IF_CORRECT = 'Indicate if this answer is correct';
export const MULTI_SELECT = 'Multi-Select';
export const SINGLE_SELECT = 'Single-Select';
export const FILL_IN_THE_BLANKS = 'Fill in the blanks';
export const PICTURE = 'Picture';
export const MIXED = 'Mixed';
export const PRE_CHECK_QUIZ = 'ADD PRE-CHECK QUIZ';
export const QUIZ = 'QUIZZES INSIDE THE VIDEO';
export const ADD_STATEMENT = 'ADD STATEMENT';
export const PUBLISH_CONFIRM_MESSAGE = 'I confirm that MLR Approval has been conducted.';
export const PUBLISH_CONFIRM_CONFIRM_TITLE = 'Allow course publish';
export const PUBLISH_CONFIRM_CONFIRM_MESSAGE =
  'Are you sure that you want to confirm that MLR Approval has been conducted? This will allow for content managers to publish this course.';
export const PUBLISH_DISABLE_CONFIRM_TITLE = 'Disable course publish';
export const PUBLISH_DISABLE_CONFIRM_MESSAGE = 'Are you sure you want to remove the permission for publishing this course?';

export const ADD_BUTTON = 'Add';
export const CANCEL_BUTTON = 'Cancel';
export const DEACTIVATE_BUTTON = 'Deactivate';
export const CLOSE_BUTTON = 'CLOSE';
export const NEXT_BUTTON = 'NEXT';
export const SAVE_NEXT_BUTTON = 'SAVE & NEXT';
export const PUBLISH_BUTTON = 'PUBLISH';
export const TEST_BUTTON = 'Test';
export const SAVE_BUTTON = 'Save';
export const ACTIVATE_BUTTON = 'Activate';
export const ACTIVATE_USER = 'Activate user';
export const INPUT_PLACEHOLDER = 'Type here...';
export const TEXTAREA_PLACEHOLDER = 'Type your message...';

export const SAVE_AND_PREVIEW_BUTTON = 'Save & Preview';
export const DELETE_BUTTON = 'Delete';
export const DUE_DATE = 'Due date';
export const COURSES = 'Courses';
export const CHOOSE_COURSE = 'Choose a course';
export const TEAM_MEMBER = 'Team Member';
export const TEAM_LEAD = 'Team Lead';
export const SUPER_ADMIN = 'Super Admin';
export const ADMIN = 'Admin';
export const CONTENT_MANAGER = 'Content Manager';
export const TYPE_YOUR_STATEMENT = 'Type your statement';
export const ENTER_A_TITLE = 'Enter a title';
export const ENTER_A_SUBTITLE = 'Enter a subtitle';
export const NAME_YOUR_COURSE = 'Name your course';
export const ENTER_A_LEARNING = 'Enter a learning objective /Optional/';

export const EDIT_USER = 'Edit User';
export const ASSIGNED_COURSES = 'Assigned courses';
export const USER_STATUS_SUMMARY = 'USER STATUS SUMMARY';
export const OVERALL_ROLE_SUMMARY = 'OVERALL ROLE SUMMARY';
export const REGION_SUMMARY = 'REGION SUMMARY';
export const COUNTRY_SUMMARY = 'COUNTRY SUMMARY';
export const ASSIGN_TO = 'Assign to';
export const ASSIGN_TEAM = 'Assign to team';
export const SELECT_COURSE = 'Select course';
export const SELECT_COUNTRY = 'Select Country/region';
export const SELECT_REGION = 'Select region';
export const SYSTEM_MESSAGE = 'System  welcome message';
export const PERSONALIZED_MESSAGE = 'Personalised welcome message';
export const SELECT_ROLE = 'Select role';
export const SELECT_THE_TEAM = 'Select the team';
export const SELECT_DATE = 'Select date';
export const SELECT_TEAM_MEMBER = 'Select team member';
export const NOT_SAVED_CHANGES = 'Your changes have not been saved yet.';
export const INPUT_TOOLTIP_TEXT = 'You can add hyperlink using [NAME OF THE LINK](HERE YOU CAN PLACE THE LINK)';

export const SELECT_MODULE = 'Select module';
export const SELECT_IF_COURSE = 'Select if this course is obligatory';
export const DATA_IMAGE = 'data:image/';
export const BASE_64 = ';base64, ';
export const PNG = 'png';
export const JPG = 'jpg';
export const JPEG = 'jpeg';
export const MP4 = 'mp4';
export const DEF_USER_MANAGEMENT_PIC = 'assets/images/default-welcome-message-image.jpg';
export const UNKNOWN_ERROR = 'An unknown error occurred!';
export const ACCESS_DENIED = 'ACCESS DENIED';
export const ACCESS_DENIED_SUBTITLE = 'Please contact your administrator to grant you access';
export const ERROR_MESSAGE = 'Oops! It seems like this page doesn’t exist.';
export const ERROR_404 = 'ERROR 404';
export const BACK = 'Back';
export const AUTH_PREFIX = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/country';
export const ROLE_PREFIX = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/';

export const USER_ASSIGNED_TO_COURSE_MESSAGE = 'The user will be notified by email for this assigned course.';

export const ARE_YOU_SURE_MESSAGE = 'Are you sure you want to continue?';
export const ADD_SECTION_CONFIRM_MESSAGE = 'Adding sections should be decreasing/increasing the percentage of completion.';
export const ADD_MODULE_CONFIRM_MESSAGE = 'Adding modules should be decreasing/increasing the percentage of completion.';
export const ADD_VIDEO_CONFIRM_MESSAGE = 'Adding videos should be decreasing/increasing the percentage of completion.';
export const ADD_KNOWLEDGE_TEST_CONFIRM_MESSAGE = 'Adding knowledge tests should be decreasing/increasing the percentage of completion.';
export const ADD_ADAPTIVE_QUIZ_CONFIRM_MESSAGE = 'Adding adaptive quizzes should be decreasing/increasing the percentage of completion.';
export const ADD_QUESTION_CONFIRM_MESSAGE = 'Adding questions should be decreasing/increasing the percentage of completion.';
export const QUESTION_CHANGES_CONFIRM_MESSAGE =
  'Making changes to a question will result in deletion of the progress of all team members for that specific question';

export const MOOD_BAROMETER_INFO_TEXT = 'Tell us how you feel about answering this question.';
export const MOOD_CONFIDENT = 'Confident';
export const MOOD_NOT_SURE = 'Not sure';
export const MOOD_NO_IDEA = 'No idea';

export const OVERVIEW = 'Overview';
export const BOOKMARKS_AND_NOTES = 'Bookmarks & Notes';

export const MEMBER_QUIZ_VIDEO_CONTINUE = 'video will continue after the quiz';

export const CORRECT_ANSWER_EXPLANATION_TEXT = 'Great! You got the correct answer!';
export const WRONG_ANSWER_EXPLANATION_TEXT = 'Hmm...This does not seem to be correct.';
export const INCORRECT_SELECTED_ANSWER = 'doesn’t seem to be correct.';

export const DROPDOWN_DEFAULT_MESSAGE = 'Pick an answer';
export const QUIZ_INPUT_PLACEHOLDER = 'Enter your response';
export const QUIZ_CORRECT_STATEMENT_MSG = 'This is the correct statement';

export const FILL_IN_THE_BLANKS_DELIMITER = '____';

export const CREATE_COURSE_TEXT = 'Create Course';

export const COLUMN_GLOBAL_ROLE = 'GlobalRole';
export const COLUMN_IS_ACTIVE = 'IsActive';
export const COLUMN_USER_RIGHT = 'UserRight';

export const APP_URL_ROUTE_LANDING_HOME = '';
export const APP_URL_ROUTE_LANDING_COURSES = 'course';
export const APP_URL_ROUTE_LANDING_ABOUT_US = 'about-us';
export const APP_URL_ROUTE_LANDING_FAQ = 'faq';
export const APP_URL_ROUTE_LANDING_CONTACT_US = 'contact-us';
export const APP_URL_ROUTE_MY_TEAM = 'my-team';
export const APP_URL_ROUTE_LEARNING_PLAN = 'learning-plan';
export const APP_URL_ROUTE_LEARNING_PERFORMANCE = 'learning-performance';
export const APP_URL_ROUTE_ADMIN_MANAGEMENT = 'management';
export const APP_URL_ROUTE_CREATE_COURSE = 'create-course';
export const APP_URL_ROUTE_MY_COURSES = 'my-courses';
export const APP_URL_ROUTE_BRANDING = 'branding';
export const APP_URL_ROUTE_TEAM_MEMBER_HOME = 'member';

export const HOME_IMG_PATH_MY_TEAM = 'assets/images/my_team.png';
export const HOME_IMG_PATH_LEARNING_PLAN = 'assets/images/learning_plan.png';
export const HOME_IMG_PATH_WELCOME_PAGE = 'assets/images/welcome_page.png';
export const HOME_IMG_PATH_LEARNING_PERFORMANCE = 'assets/images/learning_performance_home.png';
export const HOME_IMG_PATH_ADMIN_MANAGEMENT = 'assets/images/admin_management_home.png';
export const HOME_IMG_PATH_CREATE_COURSE = 'assets/images/create_course_home.png';
export const HOME_IMG_PATH_EDIT_BRANDING = 'assets/images/edit_branding.png';
export const RIGHT_ARROW_PURPLE_IMG_PATH = 'assets/images/right_arrow.svg';
export const RIGHT_ARROW_LIGHT_PURPLE_IMG_PATH = 'assets/images/right_arrow_light_purple.svg';
export const MOOD_CONFIDENT_ACTIVE_IMG_PATH = 'assets/images/confident_emoji_active.svg';
export const MOOD_NOT_SURE_ACTIVE_IMG_PATH = 'assets/images/not_sure_emoji_active.svg';
export const MOOD_NO_IDEA_ACTIVE_IMG_PATH = 'assets/images/no_idea_emoji_active.svg';

export const DYNAMIC_TEXT_AREA_STATEMENT_MAX_LENGTH = 5000;
export const DYNAMIC_TEXT_AREA_BOOKMARK_NOTE_MAX_LENGTH = 1000;
export const DYNAMIC_TEXT_AREA_LONG = 200;
export const QUIZ_TITLE_MAX_LENGTH = 85;
export const DYNAMIC_TEXT_AREA_SHORT = 80;

export const EUROPE = 'Europe';
export const LATAM = 'LATAM';
export const MEAR = 'MEAR';
export const CN = 'China';
export const APAC = 'APAC';
export const JP = 'Japan';
export const NA = 'North America';
export const OTHER = 'Other';
export const DEFAULT_URL_PROD = '4be99ba7-cb36-4f69-9f7c-8d8463ad65c7';
export const DEFAULT_URL_DEV = '54558f53-c56f-41ee-9588-a530781c2ec6';
export const COUNTENTMANAGER_CONTAINER = 'contentmanager';
export const WELCOMEFILES_CONTAINER = 'welcomefiles';

export const LOGO_TYPE = 'Logo';
export const FAV_ICON_LOGO_TYPE = 'FavIconLogo';
export const FOOTER_LOGO_TYPE = 'FooterLogo';
export const EMAIL_CAMPAIGNS_LOGO_TYPE = 'EmailCampaignsLogo';

export const WLD_LOGO_BLACK_IMG_PATH = 'assets/images/wld-logo-black.png';
export const WLD_LOGO_WHITE_IMG_PATH = 'assets/images/wld-logo-white.png';
export const WLD_LOGO_MAIN_IMG_PATH = 'assets/images/wld-logo-main.png';

export const MEMBER_WELCOME_MESSAGE = 'Welcome to the e-learning platform!';
export const CONTACT_EMAIL = 'hello@we-lovedigi.com';
export const CONTACT_PHONE = '+359 887 802 334';
